import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import IconButton from "@mui/material/IconButton";

const colorList = ["#e5f5e0", "#d7ecff", "#fee6ce"];
const colorList2 = ["#31a354", "#3283c9", "#e6550d"];
const colorList3 = ["#addd8e", "#9ecae1", "#a1d99b"];

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Approvals() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [open, setOpen] = useState(false);

  const handlePageSelect = (event) => {
    setSeeLeaves(false);
    setSelectedPage(event.currentTarget.value);
  };

  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "STUDENTLEAVES"
  );

  const colorList = ["#a1d99b", "#9ecae1", "#fdae6b"];

  const [isLoading, setIsLoading] = useState(true);

  const [leaveId, setLeaveId] = useState("");
  const [email, setEmail] = useState("");
  const [seeLeaves, setSeeLeaves] = useState("");
  const [leaveDate, setLeaveDate] = useState("");
  const [profilesApproved, setProfilesApproved] = useState([]);
  const [profilesRejected, setProfilesRejected] = useState([]);
  const [profilesPending, setProfilesPending] = useState([]);
  const [studentLeaveRequests, setStudentLeaveRequests] = useState([]);
  const [currentSemester, setCurrentSemester] = useState("");
  const [classList, setClassList] = useState([]);
  const [classListInstitution, setClassListInstitution] = useState({});
  const [noClasses, setNoClasses] = useState(false);
  const [profileIndex, setProfileIndex] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState({});
  const [currentClass, setCurrentClass] = useState("");
  const [currentDivision, setCurrentDivision] = useState("");
  const [selectedApprove, setSelectedApprove] = useState(false);
  const [semester, setSemester] = useState("");
  const [newStudents, setNewStudents] = useState(false);

  function getData(sem, classs, div, cli) {
    setIsLoading(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body["email"] = state.auth.user.email;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/get_leave_requests_principal", body, config)
      .then((res) => {
        setStudentLeaveRequests(res.data["student_requests"]);
        axios
          .post(API_URL + "/get_account_approvals_list_students", body, config)
          .then((res) => {
            var a = [];
            var r = [];
            var p = [];

            for (var i = 0; i < res.data.length; i++) {
              if (newStudents) {
                if (
                  res.data[i]["class_id"] == classs &&
                  res.data[i]["division"] == div &&
                  sem == ""
                ) {
                  if (res.data[i]["initiated"] == "PENDING") {
                    p.push(res.data[i]);
                  } else if (res.data[i]["initiated"] == "APPROVED") {
                    a.push(res.data[i]);
                  } else {
                    r.push(res.data[i]);
                  }
                }
              } else {
                if (
                  res.data[i]["class_id"] == classs &&
                  res.data[i]["division"] == div &&
                  res.data[i]["current_semester"] == sem
                ) {
                  if (res.data[i]["initiated"] == "PENDING") {
                    p.push(res.data[i]);
                  } else if (res.data[i]["initiated"] == "APPROVED") {
                    a.push(res.data[i]);
                  } else {
                    r.push(res.data[i]);
                  }
                }
              }
            }
            setCurrentSemester(Object.keys(cli[classs][div])[0]);
            setProfiles(res.data);
            setProfile(res.data[0]);
            setProfilesApproved(a);
            setProfilesRejected(r);
            setProfilesPending(p);
            setIsLoading(false);
          });
      });
  }

  function handleSemesterChange(event) {
    setCurrentSemester(event.target.value);
  }

  function handleSemesterChange2(e) {
    setSemester(e.target.value);

    setCurrentClass(Object.keys(classList[e.target.value])[0]);
    setCurrentDivision(
      Object.keys(
        classList[e.target.value][Object.keys(classList[e.target.value])[0]]
      )[0]
    );
    getData(
      e.target.value,
      Object.keys(classList[e.target.value])[0],
      Object.keys(
        classList[e.target.value][Object.keys(classList[e.target.value])[0]]
      )[0],
      classListInstitution
    );
  }

  function handleClassChange(e) {
    setIsLoading(true);
    setCurrentClass(e.target.value);
    setCurrentDivision(Object.keys(classList[semester][e.target.value])[0]);
    getData(
      semester,
      e.target.value,
      Object.keys(classList[semester][e.target.value])[0],
      classListInstitution
    );
  }

  function handleDivisionChange(e) {
    setIsLoading(true);
    setCurrentDivision(e.target.value);
    getData(semester, currentClass, e.target.value, classListInstitution);
  }

  function getSemesterList() {
    var semesters = Object.keys(classList).map((semester, index) => (
      <MenuItem value={semester}>{semester}</MenuItem>
    ));
    return semesters;
  }

  function getClassList() {
    var classes = Object.keys(classList[semester]).map((classs, index) => (
      <MenuItem value={classs}>{classs}</MenuItem>
    ));
    return classes;
  }

  function getDivisionList() {
    var classes = Object.keys(classList[semester][currentClass]).map(
      (division, index) => <MenuItem value={division}>{division}</MenuItem>
    );
    return classes;
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      var classListTemp = JSON.parse(state.auth.user.class_id_list);
      if (Object.keys(classListTemp).length == 0) {
        setIsLoading(false);
        setNoClasses(true);
      } else {
        var body = {};
        const config = {
          headers: {
            Authorization: state.auth.token,
            "X-CSRFToken": state.auth.csrfToken,
            "Content-Type": "application/json",
          },
        };
        body["institution_id"] = state.auth.user.institution_id;
        body["email"] = state.auth.user.email;
        body = JSON.stringify(body);

        axios.post(API_URL + "/get_class_list", body, config).then((res) => {
          setClassListInstitution(res.data);
          setClassList(classListTemp);
          setSemester(Object.keys(classListTemp)[0]);

          setCurrentClass(
            Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
          );
          setCurrentDivision(
            Object.keys(
              classListTemp[Object.keys(classListTemp)[0]][
                Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
              ]
            )[0]
          );
          setClassList(classListTemp);
          getData(
            Object.keys(classListTemp)[0],
            Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0],
            Object.keys(
              classListTemp[Object.keys(classListTemp)[0]][
                Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
              ]
            )[0],
            res.data
          );
        });
      }
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    axios.post(API_URL + "/get_class_list", body, config).then((res) => {
      setClassListInstitution(res.data);
      getData(semester, currentClass, currentDivision, res.data);
    });
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function approveLeave(approval) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["leave_request_id"] = leaveId;
    body["leave_date"] = leaveDate;
    body["email"] = email;
    body["approval"] = approval ? "APPROVED" : "REJECTED";
    body["date"] = moment().format("DD-MM-YYYY");
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/approve_leave_requests", body, config)
      .then((res) => {
        getData(semester, currentClass, currentDivision, classListInstitution);
        setOpen(false);
        createAlert(
          "SUCCESS",
          "Success.",
          approval ? "Leave APPROVED" : "Leave REJECTED"
        );
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function changeSubjectSelected(subjectIndex, selected) {
    var tempProfile = profile;
    var tempSubject = tempProfile["subjects"][semester][subjectIndex];
    tempProfile["subjects"][semester][subjectIndex][
      Object.keys(tempSubject)[0]
    ]["selected"] = selected;
    setProfile(tempProfile);
  }

  function approveProfile(approval) {
    var allow = true;
    for (
      var i = 0;
      i < classListInstitution[currentClass][currentDivision][semester].length;
      i++
    ) {
      if (
        Object.keys(
          classListInstitution[currentClass][currentDivision][semester][i]
        ).includes("edit")
      ) {
        if (
          classListInstitution[currentClass][currentDivision][semester][i][
            "edit"
          ] == true
        ) {
          allow = false;
        }
      }
    }

    if (allow) {
      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };
      if (approval == "DELETE") {
        body["email"] = profile["email"];
        body = JSON.stringify(body);

        axios
          .post(API_URL + "/delete_account", body, config)
          .then((res) => {
            getData(
              semester,
              currentClass,
              currentDivision,
              classListInstitution
            );
            setOpen1(false);
            setOpen3(false);

            createAlert("SUCCESS", "Success.", "Account DELETED");
          })
          .catch((err) => {
            createAlert("ERROR", "Error", "An error occured.");
          });
      } else {
        body["email"] = profile["email"];
        body["approval"] = approval;
        body["current_semester"] = currentSemester;
        body["subjects"] = profile["subjects"][currentSemester];
        body = JSON.stringify(body);

        axios
          .post(API_URL + "/approve_account", body, config)
          .then((res) => {
            getData(
              semester,
              currentClass,
              currentDivision,
              classListInstitution
            );
            setOpen1(false);
            setOpen3(false);
            createAlert(
              "SUCCESS",
              "Success.",
              approval ? "Account APPROVED" : "Account REJECTED"
            );
          })
          .catch((err) => {
            createAlert("ERROR", "Error", "An error occured.");
          });
      }
    } else {
      createAlert(
        "ERROR",
        "Error",
        "Please finalize subject names from principal panel first."
      );
    }
  }

  function getSemesters() {
    var sems = Object.keys(
      classListInstitution[currentClass][currentDivision]
    ).map((sem, index) => <MenuItem value={sem}>{sem}</MenuItem>);
    return sems;
  }

  function getPageContent() {
    if (selectedPage == "STUDENTLEAVES") {
      if (seeLeaves) {
        return (
          <Fragment>
            <Grid container spacing={1.5}>
              <Grid item md={8}>
                {profile["leaves"].length == 0 ? (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>
                        No student leave requests.
                      </h4>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {profile["leaves"].map((leave, index) => (
                  <Box
                    p={2}
                    style={{
                      height: "80px",
                      marginBottom: "15px",
                      borderRadius: "15px",
                      border:
                        leave["status"] == "PENDING"
                          ? "1.5px solid" + "#3182bd"
                          : "none",
                      backgroundColor:
                        leave["status"] == "PENDING"
                          ? "white"
                          : leave["status"] == "APPROVED"
                          ? colorList[0]
                          : colorList[2],
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItemsL: "center",
                      }}
                    >
                      <div style={{ width: "90%" }}>
                        <div
                          style={{
                            margin: "-25px 0px 0px -3px",
                          }}
                        >
                          <h3
                            style={{
                              fontFamily: "Lato-Bold",
                              lineHeight: "20px",
                              color:
                                leave["status"] == "PENDING"
                                  ? colorList2[1]
                                  : leave["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                            }}
                          >
                            {leave["subject"]}
                          </h3>
                        </div>
                        <div
                          style={{
                            margin: "-15px 0px 0px -3px",
                            fontSize: "14px",
                          }}
                        >
                          {moment(leave["date"], "DD-MM-YYYY").format(
                            "Do MMMM, YYYY"
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          width: "100px",
                          display: "flex",
                          justifyContent: "center",
                          marginTop:
                            leave["status"] == "PENDING" ? "-12px" : "-5px",
                          marginRight: "-15px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            textAlign="center"
                            p={1}
                            style={{
                              height: "35px",
                              width: "35px",
                              borderRadius: "60px",
                              backgroundColor:
                                leave["status"] == "PENDING"
                                  ? colorList2[1]
                                  : leave["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                              color: "white",
                            }}
                          >
                            {leave["status"] == "PENDING" ? (
                              <PriorityHighIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            ) : leave["status"] == "APPROVED" ? (
                              <CheckIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            ) : (
                              <CloseIcon
                                style={{
                                  fontSize: "30px",
                                  margin: "-5px 0px 0px -5.5px",
                                }}
                              />
                            )}
                          </Box>
                          <b
                            style={{
                              marginTop: "5px",
                              color:
                                leave["status"] == "PENDING"
                                  ? colorList2[1]
                                  : leave["status"] == "APPROVED"
                                  ? colorList2[0]
                                  : colorList2[2],
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Bahnschrift",
                                fontWeight: "lighter",
                              }}
                            >
                              {leave["status"]}
                            </span>
                          </b>
                        </div>
                      </div>
                    </div>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Grid container spacing={1.5}>
              {studentLeaveRequests.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>
                      No student leave requests.
                    </h4>
                  </div>
                </div>
              ) : (
                ""
              )}
              {studentLeaveRequests.map((leave, index) => (
                <Grid item md={3}>
                  <Box
                    style={{
                      minHeight: "100%",
                      borderRadius: "6px",
                      border: "1.3px solid #d7ecff",
                      backgroundColor: "white",
                      boxShadow: "none",
                      padding: "15px",
                    }}
                  >
                    <div
                      style={{
                        margin: "-5px 0px 0px 0px",
                        fontSize: "18px",
                        color: "#084594",
                        fontFamily: "Lato-Bold",
                      }}
                    >
                      {leave["name"]}
                    </div>
                    <div style={{ fontSize: "14px" }}>{leave["subject"]}</div>
                    <div
                      style={{
                        marginTop: "10px",
                        color: "#3182bd",
                        fontFamily: "Lato-Bold",
                      }}
                    >
                      {leave["class"]}
                    </div>
                    <div style={{ marginTop: "-5px" }}>
                      {" "}
                      {moment(leave["date"], "DD-MM-YYYY").format(
                        "Do MMMM, YYYY"
                      )}
                    </div>

                    <div style={{ margin: "0px 0px 0px 0px" }}>
                      {leave["description"]}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "-5px",
                        marginRight: "-5px",
                      }}
                    >
                      <Fab
                        size="small"
                        style={{
                          transform: "scale(0.8)",
                          boxShadow: "none",
                          backgroundColor: "#3182bd",
                        }}
                        onClick={() => {
                          setLeaveId(leave["id"]);
                          setEmail(leave["email"]);
                          setLeaveDate(leave["date"]);
                          setOpen(true);
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "white",
                          }}
                        />
                      </Fab>
                    </div>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Fragment>
        );
      }
    } else if (selectedPage == "PROFILES") {
      return (
        <Grid container spacing={1.5}>
          <Grid item md={9}>
            <Fragment>
              {profiles.length == 0 ||
              (profilesApproved.length == 0 &&
                profilesRejected.length == 0 &&
                profilesPending.length == 0) ? (
                <Grid container spacing={1.5} style={{ marginTop: "-20px" }}>
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>
                        No Teacher profile approvals pending.
                      </h4>
                    </div>
                  </div>
                </Grid>
              ) : (
                <Fragment>
                  {profilesPending.length != 0 ? <h3>Pending Profiles</h3> : ""}
                  <Grid container spacing={1.5}>
                    {profilesPending.map((profile, index) => (
                      <Grid item md={3}>
                        <Box
                          onClick={() => {
                            if (profile["current_semester"].length != 0) {
                              setCurrentSemester(profile["current_semester"]);
                            }
                            setProfile(profile);
                            setProfileIndex(index);
                            setOpen1(true);
                          }}
                          style={{
                            cursor: "pointer",
                            minHeight: "100%",
                            borderRadius: "6px",
                            border: "1.3px solid #d7ecff",
                            backgroundColor: "white",
                            boxShadow: "none",
                            padding: "15px",
                          }}
                        >
                          <div
                            style={{
                              margin: "-5px 0px 0px 0px",
                              fontSize: "18px",
                              color: "#084594",
                              fontFamily: "Lato-Bold",
                            }}
                          >
                            {profile["email"]}
                          </div>
                          <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                            {profile["firstname"] + " " + profile["lastname"]}
                          </div>
                          <div style={{ fontSize: "14px" }}>
                            {profile["class_id"] + " " + profile["division"]}
                          </div>{" "}
                          <div style={{ fontSize: "14px", color: "#084594" }}>
                            {"Roll No " + profile["roll_no"]}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginBottom: "-5px",
                              marginRight: "-5px",
                            }}
                          >
                            <Fab
                              size="small"
                              style={{
                                transform: "scale(0.8)",
                                boxShadow: "none",
                                backgroundColor: "#3182bd",
                              }}
                              onClick={() => {
                                setProfile(profile);
                                setOpen1(true);
                              }}
                              aria-label="add"
                            >
                              <PriorityHighIcon
                                style={{
                                  color: "white",
                                }}
                              />
                            </Fab>
                          </div>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  {profilesApproved.length != 0 ? (
                    <h3>Approved Profiles</h3>
                  ) : (
                    ""
                  )}
                  <Grid container spacing={1.5}>
                    {profilesApproved.map((profile, index) => (
                      <Grid item md={3}>
                        <Box
                          onClick={() => {
                            setProfile(profile);
                            setOpen1(true);
                          }}
                          style={{
                            cursor: "pointer",
                            minHeight: "100%",
                            borderRadius: "6px",
                            border: "1.3px solid #d7ecff",
                            backgroundColor: "white",
                            boxShadow: "none",
                            padding: "15px",
                          }}
                        >
                          <div
                            style={{
                              margin: "-5px 0px 0px 0px",
                              fontSize: "18px",
                              color: "#084594",
                              fontFamily: "Lato-Bold",
                            }}
                          >
                            {profile["email"]}
                          </div>
                          <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                            {profile["firstname"] + " " + profile["lastname"]}
                          </div>
                          <div style={{ fontSize: "14px" }}>
                            {profile["class_id"] + " " + profile["division"]}
                          </div>{" "}
                          <div style={{ fontSize: "14px", color: "#084594" }}>
                            {"Roll No " + profile["roll_no"]}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginBottom: "-5px",
                              marginRight: "-5px",
                            }}
                          >
                            <Fab
                              size="small"
                              style={{
                                transform: "scale(0.8)",
                                boxShadow: "none",
                                backgroundColor: "#a1d99b",
                              }}
                              onClick={() => {
                                setProfile(profile);
                                setOpen1(true);
                              }}
                              aria-label="add"
                            >
                              <CheckIcon
                                style={{
                                  color: "white",
                                }}
                              />
                            </Fab>
                          </div>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  {profilesRejected.length != 0 ? (
                    <h3>Rejected Profiles</h3>
                  ) : (
                    ""
                  )}
                  <Grid container spacing={1.5}>
                    {profilesRejected.map((profile, index) => (
                      <Grid item md={3}>
                        <Box
                          onClick={() => {
                            setProfile(profile);
                            setOpen1(true);
                          }}
                          style={{
                            cursor: "pointer",
                            minHeight: "100%",
                            borderRadius: "6px",
                            border: "1.3px solid #d7ecff",
                            backgroundColor: "white",
                            boxShadow: "none",
                            padding: "15px",
                          }}
                        >
                          <div
                            style={{
                              margin: "-5px 0px 0px 0px",
                              fontSize: "18px",
                              color: "#084594",
                              fontFamily: "Lato-Bold",
                            }}
                          >
                            {profile["email"]}
                          </div>
                          <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                            {profile["firstname"] + " " + profile["lastname"]}
                          </div>
                          <div style={{ fontSize: "14px" }}>
                            {profile["class_id"] + " " + profile["division"]}
                          </div>{" "}
                          <div style={{ fontSize: "14px", color: "#084594" }}>
                            {"Roll No " + profile["roll_no"]}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginBottom: "-5px",
                              marginRight: "-5px",
                            }}
                          >
                            <Fab
                              size="small"
                              style={{
                                transform: "scale(0.8)",
                                boxShadow: "none",
                                backgroundColor: "#ff3333",
                              }}
                              onClick={() => {
                                setProfile(profile);
                                setOpen1(true);
                              }}
                              aria-label="add"
                            >
                              <CloseIcon
                                style={{
                                  color: "white",
                                }}
                              />
                            </Fab>
                          </div>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>{" "}
                </Fragment>
              )}
            </Fragment>
          </Grid>
          <Grid item xs={3} style={{ paddingRight: "15px" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <h4>Show new students</h4>

              <Checkbox
                disableRipple={true}
                labelStyle={{ color: "#3182bd" }}
                iconStyle={{ fill: "white" }}
                checked={newStudents}
                onChange={(e) => {
                  setNewStudents(e.target.checked);
                  getData(
                    "",
                    currentClass,
                    currentDivision,
                    classListInstitution
                  );
                }}
                value={1}
                style={{
                  transform: "scale(1.2)",
                  color: "#3182bd",
                  marginTop: "-5px",
                }}
              />
            </div>

            <span
              style={{
                color: "#3182bd",
                fontFamily: "Lato-Regular",
                fontSize: "14px",
              }}
            >
              Semester
            </span>

            <Select
              value={semester}
              onChange={(e) => handleSemesterChange2(e)}
              style={{
                marginTop: "2px",
                height: "40px",
                marginBottom: "5px",
              }}
              fullWidth
              variant={"outlined"}
            >
              {getSemesterList()}
            </Select>

            <span
              style={{
                color: "#3182bd",
                fontFamily: "Lato-Regular",
                fontSize: "14px",
              }}
            >
              Class
            </span>
            <Select
              value={currentClass}
              onChange={(e) => handleClassChange(e)}
              style={{
                marginTop: "2px",
                height: "40px",
                marginBottom: "15px",
              }}
              fullWidth
              variant={"outlined"}
            >
              {getClassList()}
            </Select>
            <span
              style={{
                color: "#3182bd",
                fontFamily: "Lato-Regular",
                fontSize: "14px",
              }}
            >
              Division
            </span>

            <Select
              value={currentDivision}
              onChange={(e) => handleDivisionChange(e)}
              style={{ marginTop: "2px", height: "40px" }}
              fullWidth
              variant={"outlined"}
            >
              {getDivisionList()}
            </Select>
          </Grid>
        </Grid>
      );
    }
  }

  function getApproveData() {
    if (
      profiles.length == 0 ||
      !Object.keys(profile["subjects"]).includes(semester)
    ) {
      return <span></span>;
    } else {
      return (
        <div>
          {/* <h1>{profile[profileIndex]["firstname"]} </h1> */}

          {profile["subjects"][semester].map((subject, index) => (
            <div>
              {subject[Object.keys(subject)[0]]["compulsary"] == false ? (
                <div
                  style={{
                    display: "flex",
                    marginBottom: "-30px",
                  }}
                >
                  <h4>{Object.keys(subject)[0]}</h4>
                  <Checkbox
                    disableRipple={true}
                    labelStyle={{ color: "#3182bd" }}
                    iconStyle={{ fill: "white" }}
                    checked={subject[Object.keys(subject)[0]]["selected"]}
                    onChange={(e) => {
                      changeSubjectSelected(index, e.target.checked);
                    }}
                    value={1}
                    style={{
                      transform: "scale(1.2)",
                      color: "#3182bd",
                      marginTop: "-5px",
                    }}
                  />
                </div>
              ) : (
                <span></span>
              )}
            </div>
          ))}
        </div>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_teacher) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginTop: "46vh",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5} justifyContent="flex-end">
                    <Grid item md={4}>
                      {selectedPage == "STUDENTLEAVES" ? (
                        <Button
                          fullWidth
                          value={"STUDENTLEAVES"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            STUDENT LEAVES
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"STUDENTLEAVES"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            STUDENT LEAVES
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "PROFILES" ? (
                        <Button
                          fullWidth
                          value="PROFILES"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            PROFILES
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="PROFILES"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            PROFILES
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              />
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>Approve this leave request?</h3>
                      <span>This action cannot be reversed.</span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => approveLeave(true)}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Approve
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => approveLeave(false)}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              {noClasses ? (
                ""
              ) : (
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open1}
                  onClose={() => setOpen1(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open1}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        padding: "20px",
                      }}
                    >
                      <div style={{ marginTop: "-25px" }}>
                        <div
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton size="small">
                            <CloseIcon
                              onClick={() => setOpen1(false)}
                              style={{ color: "#3182bd" }}
                            />
                          </IconButton>
                        </div>
                        <h3>Approve this student account? </h3>
                        <div
                          style={{
                            marginTop: "-20px",
                            marginBottom: "-30px",
                          }}
                        >
                          This action cannot be reversed.
                        </div>
                        <br></br> <br></br>
                        Current Semester :
                        <Select
                          style={{
                            marginTop: "2px",
                            height: "40px",
                            marginBottom: "15px",
                          }}
                          variant={"outlined"}
                          fullWidth
                          value={currentSemester}
                          onChange={(e) => handleSemesterChange(e)}
                        >
                          {getSemesters()}
                        </Select>
                        {getApproveData()}
                        <br></br>
                        <br></br>
                        <br></br>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setSelectedApprove(true);
                              setOpen3(true);
                            }}
                            style={{ height: "30px" }}
                            variant="contained"
                          >
                            Approve
                          </Button>
                          <Button
                            style={{ height: "30px", marginLeft: "10px" }}
                            variant="contained"
                            onClick={() => {
                              setSelectedApprove(false);
                              setOpen3(true);
                            }}
                          >
                            Reject
                          </Button>
                          <Button
                            onClick={() => {
                              setSelectedApprove("DELETE");
                              setOpen3(true);
                            }}
                            style={{
                              height: "30px",
                              marginLeft: "10px",
                              backgroundColor: "#ff3333",
                            }}
                            variant="contained"
                          >
                            Delete
                          </Button>
                          <Button
                            onClick={() => {
                              setSelectedPage("STUDENTLEAVES");
                              setSeeLeaves(true);
                              setOpen1(false);
                            }}
                            style={{
                              height: "30px",
                              marginLeft: "10px",
                              backgroundColor: "#1c9099",
                            }}
                            variant="contained"
                          >
                            Leaves
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </Modal>
              )}
              {noClasses ? (
                ""
              ) : (
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open3}
                  onClose={() => setOpen3(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open3}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        padding: "20px",
                      }}
                    >
                      <div style={{ marginTop: "-25px" }}>
                        <div
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton size="small">
                            <CloseIcon
                              onClick={() => setOpen3(false)}
                              style={{ color: "#3182bd" }}
                            />
                          </IconButton>
                        </div>
                        <h3>Are you sure?</h3>
                        <span>This action cannot be reversed.</span>
                        <br></br>
                        <br></br>
                        <br></br>

                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            onClick={() => setOpen3(false)}
                            style={{ height: "30px" }}
                            variant="contained"
                          >
                            NO
                          </Button>
                          <Button
                            style={{ height: "30px", marginLeft: "10px" }}
                            variant="contained"
                            onClick={() => approveProfile(selectedApprove)}
                          >
                            YES
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </Modal>
              )}

              <div
                style={{
                  margin: "65px 15px 20px 85px",
                  backgroundColor: "white",
                }}
              >
                {noClasses ? (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>
                        No classes or subjects appointed.
                      </h4>
                    </div>
                  </div>
                ) : (
                  <div>
                    {noClasses ? (
                      <h3>No Classes or subjects appointed.</h3>
                    ) : (
                      getPageContent()
                    )}
                  </div>
                )}
              </div>
            </Fragment>
          );
        }
      } else {
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Approvals;
